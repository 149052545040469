<template>
	<div class="report-list" v-if="listData && listData.length>0">
		<div class="list-box" v-for="(list, index) of listData" :key="index" >
			<img v-if="list.ttwTpDO && list.ttwTpDO.ydlj && list.ttwTpDO.ydlj !== '' && list.ttwTpDO.ydlj !== null" :src="list.ttwTpDO.ydlj" />
			<img v-else src="../assets/images/footer/xwbd.jpg" />
			<div class="text-box">
				<span class="text-title" @click="toDetail(list)">{{list.bt}}</span>
				<span class="text-time">{{list.fbsj}}</span>
				<p class="text-con">{{list.wznr}}</p>
			    <button  @click="toDetail(list)">查看详情</button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			listData:null
		},
		data() {
			return {
			}
		},
		methods: {
            toDetail(list){
				this.$router.push({
					name: "newsDetail",
					query: {
						id:list.id
					}
				});
			}
		}
	}
</script>

<style lang="scss">
	.report-list {
		.list-box {
			height: 220px;
			padding: 28px 44px 28px 20px;
			box-sizing: border-box;
            border-bottom:1px solid rgba(217,217,217,1);
			position: relative;
			img {
				width: 248px;
				height: 165px;
				margin-right: 33px;
				float: left;
			}
			.text-box {
				overflow: hidden;
				.text-title {
					font-size:18px;
					font-family:Alibaba PuHuiTi;
					font-weight:400;
					color:rgba(81,82,84,1);
					line-height: 25px;
					cursor:pointer;
					overflow:hidden;
					display:-webkit-box;
					-webkit-line-clamp:1;
					-webkit-box-orient: vertical;
				}
				.text-time{
					display: block;
					font-size:12px;
					font-family:Source Han Sans CN;
					font-weight:400;
					color:rgba(150,150,150,1);
					line-height:35px;
				}
				.text-con{
					max-height:66px;
					font-size:14px;
					text-align:justify;
					font-family:Alibaba PuHuiTi;
					font-weight:400;
					color:rgba(102,102,102,1);
					line-height:22px;
					overflow:hidden;
					display:-webkit-box;
					-webkit-line-clamp:3;
					-webkit-box-orient: vertical;
				}
				button{
					width:100px;
					height:30px;
					cursor:pointer;
					background:rgba(255,255,255,1);
					border:1px solid rgba(217,217,217,1);
					font-size:14px;
					font-family:Source Han Sans CN;
					font-weight:400;
					color:rgba(0,0,0,1);
					position: absolute;
					top: 164px;
				}
			}

		}
		.page-block {
			padding: 31px 240px 91px 240px;
		}
		.list-box:hover{

			background:rgba(242,242,242,1);

			.text-box{
				.text-title{
					color:rgba(0,120,255,1);
				}
				button{
					background:rgba(3,155,229,1);
					color:rgba(255,255,255,1);
				}
			}
		}
	}

</style>
