<template>
    <div class="news center">
        <bread-crumb></bread-crumb>
        <report-list v-if="reportData && reportData.length>0" :listData="reportData"></report-list>
        <el-empty v-else description="暂无数据"></el-empty>
        <div class="pagination">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="start"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
    import breadCrumb from '@/components/common/breadCrumb'
    import reportList from '@/components/reportList'
    import {getGraphInfoByClass} from "../../api/homeService";

    export default {
        components: {
            breadCrumb,
            reportList,
        },
        data() {
            return {
                start:1,
                size:10,
                total:0,
                reportData: null,
				isActive:true
            }
        },
        mounted(){
            this.getGraphInfoList();
        },
        methods: {
            getGraphInfoList(){
                const data = {lx: '1',size: this.size,start: this.start};
                getGraphInfoByClass(data).then(res=>{
                    this.reportData = [...res?.data] ?? [];
                    this.total = res.total;
                })
            },
            handleSizeChange(val){
                this.size = val;
                this.getGraphInfoList();
            },
            handleCurrentChange(val){
                this.start = val;
                this.getGraphInfoList();
            }
        }
    }
</script>
<style lang="scss">
    .news {
		.step {
			padding: 12px 0px;
			font-size: 16px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: rgba(102, 102, 102, 1);
		}
		.active {
			color: rgba(0, 120, 255, 1);
		}
	}
    .pagination{
        text-align:center;
        margin:20px 0;
        .el-pagination{
            font-weight:500;
        }
    }
</style>
